var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.showTitle)?_c('v-card-title',[_vm._v(" 实验室列表 ")]):_vm._e(),_c('v-row',[_c('v-col',[(!_vm.contestMode)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search ID","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchByLabId($event)},"click:append":_vm.searchByLabId},model:{value:(_vm.searchLabId),callback:function ($$v) {_vm.searchLabId=$$v},expression:"searchLabId"}}):_vm._e()],1),(_vm.adminMode)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"primary",attrs:{"block":"","x-large":""},on:{"click":_vm.putLab}},[_vm._v("创建实验室")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"lab_list_table",attrs:{"headers":_vm.showTableHeaders(),"items":_vm.labList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.clickTableRow},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.contestChooseMode)?_c('v-container',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.addContestLab(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-playlist-plus ")])]}}],null,true)},[_c('span',[_vm._v("添加到列表末尾")])])],1):_vm._e(),(_vm.adminMode)?_c('v-container',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.editLab(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("编辑")])]),(item.lab_info.status === 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.disableLab(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-test-tube-off ")])]}}],null,true)},[_c('span',[_vm._v("禁用")])]):_vm._e(),(item.lab_info.status !== 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.enableLab(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-test-tube ")])]}}],null,true)},[(item.lab_info.status === -2 || item.lab_info.status === -3)?_c('span',[_vm._v("恢复")]):_vm._e(),(item.lab_info.status === 0)?_c('span',[_vm._v("可用")]):_vm._e()]):_vm._e()],1):_vm._e()]}}])}),(!_vm.contestMode)?_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},on:{"input":_vm.clickPagination},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
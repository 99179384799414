var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.showTitle)?_c('v-card-title',[_vm._v(" 竞赛列表 ")]):_vm._e(),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search ID","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchByContestId($event)},"click:append":_vm.searchByContestId},model:{value:(_vm.searchContestId),callback:function ($$v) {_vm.searchContestId=$$v},expression:"searchContestId"}})],1),(_vm.adminMode)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"primary",attrs:{"block":"","x-large":""},on:{"click":_vm.putContest}},[_vm._v("创建比赛")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"lab_list_table",attrs:{"headers":_vm.adminMode || _vm.contestChooseMode ? _vm.headersAdmin : _vm.headers,"items":_vm.contestList,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"click:row":_vm.clickTableRow},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isInSigninTime(item) && !item.is_signed)?_c('v-container',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.signinContest(item.contest_info.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar-import ")])]}}],null,true)},[_c('span',[_vm._v("报名")])])],1):_vm._e(),(item.is_signed)?_c('v-container',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar-check ")])]}}],null,true)},[_c('span',[_vm._v("已报名")])])],1):_vm._e(),(_vm.adminMode)?_c('v-container',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.editContest(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("编辑")])]),(item.contest_info.status === 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.modifyContestStatus(item, _vm.StatusMap.STATUS_DISABLE)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-test-tube-off ")])]}}],null,true)},[_c('span',[_vm._v("禁用")])]):_vm._e(),(item.contest_info.status !== 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.modifyContestStatus(item, _vm.StatusMap.STATUS_ENABLE)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-test-tube ")])]}}],null,true)},[(item.contest_info.status === _vm.StatusMap.STATUS_DISABLE)?_c('span',[_vm._v("可用")]):_vm._e()]):_vm._e()],1):_vm._e()]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":7},on:{"input":_vm.clickPagination},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }